import { render, staticRenderFns } from "./PageHeaderCounts.vue?vue&type=template&id=9c4f38e8&scoped=true"
import script from "./PageHeaderCounts.vue?vue&type=script&lang=js"
export * from "./PageHeaderCounts.vue?vue&type=script&lang=js"
import style0 from "./PageHeaderCounts.vue?vue&type=style&index=0&id=9c4f38e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c4f38e8",
  null
  
)

export default component.exports